<template>
  <ArticleCardsCollection
    v-if="hasArticles"
    :articles="formattedArticles"
    :cta="ctaLink"
    :layout
    :subtitle
    :title
    :tracking
  />
</template>

<script setup lang="ts">
import { useRequestURL } from '#imports'
import { computed } from 'vue'

import type { ArticleCardsList } from '@backmarket/http-api/src/api-specs-content/models/article-cards-list'

import type { ContentBlockProps } from '../../models/content-block'
import ArticleCardsCollection from '../../shared-components/ArticleCardsCollection/ArticleCardsCollection.vue'
import { mapArticleCardsListToArticleCards } from '../../utils/articleMapper'

const currentUrl = useRequestURL()

export type ArticleCardsListProps = ArticleCardsList & ContentBlockProps
const props = withDefaults(defineProps<ArticleCardsListProps>(), {
  title: '',
  subtitle: '',
  layout: 'carousel',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const formattedArticles = computed(() => {
  return mapArticleCardsListToArticleCards(currentUrl, props)
})

const hasArticles = computed(() => formattedArticles.value.length > 0)
</script>
