import { useRuntimeConfig } from '#imports'

import type { OrderlineTimelineStep } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { spacesToHyphens } from '@backmarket/utils/string/spacesToHyphens'

import type {
  OrderlineTimelineEvent,
  OrderlineTimelineStatusTracker,
} from '../OrderlineTimeline.types'

function parseDate(value: string | null): string | undefined {
  if (typeof value === 'string') {
    const i18n = useI18n()

    return i18n.date(new Date(value), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  }

  return undefined
}

function getTimelineStepDetails(
  orderlineTimelineStep: OrderlineTimelineStep,
  shouldDisplayDateAsRange: boolean,
) {
  const { dateRange, details } = orderlineTimelineStep
  if (dateRange && shouldDisplayDateAsRange) {
    const DATE_PLACEHOLDER_KEY = '%s'

    return dateRange.maxDateDetails.replace(
      DATE_PLACEHOLDER_KEY,
      parseDate(dateRange.maxDate) || '',
    )
  }

  return details || undefined
}

function getTimelineStepDate(
  orderlineTimelineStep: OrderlineTimelineStep,
  shouldDisplayDateAsRange: boolean,
) {
  if (orderlineTimelineStep.dateRange && shouldDisplayDateAsRange) {
    return parseDate(orderlineTimelineStep.dateRange.minDate)
  }

  return parseDate(orderlineTimelineStep.date)
}

export function useOrderlineTimelineStatusTracker(
  orderlineTimelineSteps: OrderlineTimelineStep[],
): OrderlineTimelineStatusTracker {
  const { FF_ENABLE_MY_ORDERS_TIMELINE_STEPS_AS_DATE_RANGE } =
    useRuntimeConfig().public
  const shouldDisplayTimelineEventDateAsRange =
    FF_ENABLE_MY_ORDERS_TIMELINE_STEPS_AS_DATE_RANGE as boolean

  const steps = orderlineTimelineSteps
    .filter((step): step is OrderlineTimelineEvent => step.type !== null)
    .map((event) => {
      const eventKey = spacesToHyphens(event.type)

      return {
        current: event.current,
        hint: event.hint || undefined,
        label: event.label,
        key: eventKey,
        date: getTimelineStepDate(event, shouldDisplayTimelineEventDateAsRange),
        details: getTimelineStepDetails(
          event,
          shouldDisplayTimelineEventDateAsRange,
        ),
      }
    })

  const currentStep = steps.find((step) => step.current)

  return {
    currentStepKey: currentStep?.key || '',
    steps,
  }
}
